import React from "react";
import "./Footer.css";

// TODO: include wikisource and gutenberg
function Footer() {
  return <footer>
    <p>
      Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
    </p>
  </footer>;
}

export default Footer;